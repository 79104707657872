.floor-buttons {
  display: none;
  position: absolute;
  bottom: -20px;
  right: 15px;
  // display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  &--btn {
    color: white;
    padding: 5px 10px;
    width: fit-content;
    white-space: nowrap;
    background-color: #123e3dbd;
    flex: 0 1 auto;
    white-space: nowrap;
    position: relative;
    font-size: 12px;
    text-transform: uppercase;
    cursor: pointer;

    &:hover {
      color: #f6da8f;
      background-color: #123e3dde;
    }

    &.active {
      color: #f6da8f;
      background-color: #123e3dde;
    }
  }

  @media (min-width: 768px) {
    display: flex;
  }
}

.mobile-floor--buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 13px;
  padding: 0 20px;

  &-btn {
    color: white;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
    &.active {
      background-color: rgba(255, 255, 255, 0.3);
      border: 1px solid rgba(255, 255, 255, 0.3);
      border-radius: 15px;
      padding: 5px 10px;
    }
  }
}
