.khp-outdoor-hotspot {
  width: 30px;
  height: 30px;
  background-color: rgba(227, 207, 143, 0.5);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: all;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
    background-color: #e3cf8f;
    border-radius: 50%;
  }

  &::after {
    display: block;
    position: absolute;
    content: '';
    width: 20px;
    height: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 100%;
    // border: 1px solid #d6b623;
    background-color: transparent;
    animation: outdoorPulsePoint 1s infinite;
  }

  &__title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, calc(-50% - 30px));
    white-space: nowrap;
    background-color: #123E3D;
    padding: 5px 15px;
    border-radius: 8px;
    line-height: 20px;
    font-size: 12px;
    width: 200px;
    text-align: center;
    border: 1px solid rgb(214, 182, 35);
    color: rgb(214, 182, 35);
    padding-top: 15px;
    display: none;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .khp-outdoor-hotspot__title-text {
      margin: 0;
      line-height: 16px;
      text-transform: uppercase;
    }
  }

  &__thumb {
    position: absolute;
    top: -90px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% + 2px);
    height: 100px;
    border: 1px solid rgb(214, 182, 35);
    border-bottom-width: 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &:hover {
    .khp-outdoor-hotspot__title {
      display: flex;
    }
  }
}

@keyframes outdoorPulsePoint {
  0% {
    box-shadow: 0 0 0 0 rgb(255, 255, 255);
  }

  70% {
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}