@mixin visibilityHidden {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition: visibility 0s, opacity 0.3s ease-in-out;
}
@mixin visibilityVisible {
  visibility: visible;
  opacity: 1;
  pointer-events: all;
  transition: visibility 0s, opacity 0.3s ease-in-out;
}

$background-primary: #123e3d;

#bottom-menu-v2 {
  font-family: var(--montserrat-font);
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $background-primary;
  padding: 0;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 21;
  min-height: 50px;

  .menu--items {
    display: flex;
    align-items: center;

    .logo-tour {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      padding-inline: 30px;
      img {
        height: 2.6rem;
      }
    }

    .item {
      background: none;
      border: none;
      border-left: 0.5px solid #495250;
      color: #ffffff;
      font-size: 14px;
      font-weight: 500;
      text-transform: uppercase;
      cursor: pointer;
      padding: 17px 20px 15px;
      transition: color 0.3s ease;
      height: 100%;
      position: relative;

      .item-label {
        font-weight: bold;
      }

      &:last-child {
        border-right: 0.5px solid #495250;
      }

      .sub-menu {
        @include visibilityHidden();
        position: absolute;
        top: 5px;
        left: 50%;
        transform: translate(-36%, -100%);
        background-color: $background-primary;
        z-index: 1;
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
        justify-content: center;
        width: 1000px;
        list-style-type: none;
        background-color: transparent;
        padding-bottom: 10px;
        font-weight: 400;

        .sub-item {
          padding: 10px;
          width: fit-content;
          white-space: nowrap;
          background-color: #123e3dbd;
          flex: 0 1 auto;
          white-space: nowrap;
          position: relative;
          font-size: 12px;

          &:hover {
            background-color: #123e3dde;
            color: #f6da8f;
          }

          .img-preview {
            @include visibilityHidden();

            position: absolute;
            min-width: 200px;
            max-width: 250px;
            aspect-ratio: 16/9;
            object-fit: cover;
            top: 5px;
            left: 0;
            transform: translateY(-110%);
            box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
              rgba(0, 0, 0, 0.12) 0px -12px 30px,
              rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px,
              rgba(0, 0, 0, 0.09) 0px -3px 5px;
          }
        }
      }

      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
        position: relative;

        .item-label {
          color: #f6da8f;
        }

        .sub-menu {
          display: flex;
          @include visibilityVisible();

          .sub-item:hover .img-preview {
            @include visibilityVisible();
          }
        }
      }

      &:hover::after {
        content: '';
        width: calc(100% + 2px);
        height: 3px;
        background-color: #f6da8f;
        position: absolute;
        top: 0;
        left: -1px;
        z-index: 2;
      }
    }
  }

  .tour-logo {
    position: fixed;
    z-index: 10;
    top: 10px;
    left: 10px;
    img {
      width: 80px;
    }
  }
}

#menu-social {
  position: fixed;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  border: 1px solid #d3a524;
  padding: 3px 0 3px 3px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: space-around;
    padding: 5px 0;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    background-color: #d3a524;

    .social-item {
      padding: 8px 10px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .social-item:hover svg {
      transition: filter 0.2s ease;
      filter: drop-shadow(3px 5px 2px rgba(0, 0, 0, 0.4));
    }
  }
}

.menu--actions {
  display: flex;

  .action {
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 0.5px solid #495250;
    padding-inline: 20px;
  }

  #actions-icons {
    display: flex;
    gap: 10px;

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      svg {
        width: 24px;
      }
    }
  }

  #back-button {
    position: absolute;
    right: 6px;
    top: -90%;
    padding: 8px 15px;
    background: rgba(23, 62, 61, 0.8);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    font-size: 16px;
    transition: background 0.3s ease;
    border-radius: 6px;
    cursor: pointer;
    &:hover {
      background: rgb(23, 62, 61);
    }
  }
}

.hamburger {
  padding-left: 20px;
  .line {
    width: 25px;
    height: 2px;
    background-color: #ecf0f1;
    display: block;
    margin: 5px auto;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    cursor: pointer;
  }
}

#hamburger-1.is-active .line:nth-child(2) {
  opacity: 0;
}

#hamburger-1.is-active .line:nth-child(1) {
  -webkit-transform: translateY(7px) rotate(45deg);
  -ms-transform: translateY(7px) rotate(45deg);
  -o-transform: translateY(7px) rotate(45deg);
  transform: translateY(7px) rotate(45deg);
}

#hamburger-1.is-active .line:nth-child(3) {
  -webkit-transform: translateY(-7px) rotate(-45deg);
  -ms-transform: translateY(-7px) rotate(-45deg);
  -o-transform: translateY(-7px) rotate(-45deg);
  transform: translateY(-7px) rotate(-45deg);
}

#bottom-menu-v2__mobile {
  @include visibilityHidden();
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - 50px);
  background-color: #123e3dcc;
  z-index: 20;
  transform: translateY(100%);
  transition: transform 0.3s ease-in-out, visibility 0.3s, opacity 0.3s;

  &.visible {
    @include visibilityVisible();
    transform: translateY(0);
    transition: transform 0.3s ease-in-out, visibility 0.3s, opacity 0.3s;
  }

  .mobile-menu-wrapper {
    color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    width: 100%;
    position: fixed;
    height: 100%;
    display: flex;
    flex-direction: column;

    .menu-header {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;

      img {
        width: 9rem;
      }
    }

    .menu-items {
      margin-top: 10px;
      list-style: none;
      padding: 0;
      flex: 1;
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow-y: auto;
      padding-bottom: 80px;

      .item {
        background-color: transparent;
        border: none;
        border-top: 0.5px solid rgba(255, 255, 255, 0.3);
        padding: 20px 0;
        text-align: left;
        color: white;
        font-weight: 400;
        cursor: pointer;

        &:nth-child(3) {
          border-bottom: 0.5px solid rgba(255, 255, 255, 0.3);
        }

        .item-label-wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-inline: 20px;
          transition: color 0.3s ease;
          svg {
            transform: rotate(90deg) scale(1.2);
            fill: #ffffff;
            transition: transform 0.3s ease;
          }
          .item-label {
            text-transform: uppercase;
            font-size: 15px;
          }
          &.active {
            color: #f6da8f;
            svg {
              transform: rotate(180deg) scale(1.2);
              fill: #f6da8f;
            }
          }
        }

        &:has(.sub-menu) {
          padding-bottom: 0; // Set padding-bottom to 0
        }
      }

      .sub-menu {
        margin-top: 20px;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.3s ease-in-out;
        li {
          list-style: none;
          padding-left: 20px;
          border-top: 0.5px solid rgba(255, 255, 255, 0.3);
          padding: 20px 0 20px 40px;
          text-transform: uppercase;
          cursor: pointer;
          font-size: 12px;
          &::before {
            content: '- ';
          }
        }
      }
      .sub-menu.expanded {
        max-height: 500px; /* Adjust this value based on your content */
      }
    }

    .social-icons {
      display: flex;
      justify-content: center;
      margin: 30px 0;

      .container {
        display: flex;
        gap: 10px;
      }

      .social-item {
        margin: 0 10px;
        color: #ffffff;
        font-size: 24px;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        svg {
          width: 24px;
          height: 24px;
        }

        &:hover {
          color: #f6da8f; // Change color on hover
        }
      }
    }

    .back-to-home {
      color: #ffffff;
      text-decoration: none;
      text-align: center;
      width: fit-content;
      border: 1px solid rgba(255, 255, 255, 0.3);
      padding: 10px;
      margin-inline: auto;
      font-size: 12px;
    }
  }
}
