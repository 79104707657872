.khp-media-hotspot {
  width: 8px;
  height: 8px;
  background-color: rgb(222, 221, 221);
  // background-color: white;
  box-shadow: 1px 1px 1px black;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;

  &::before {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    // background-color: rgba(255, 255, 255, 0.65);
    background-color: white;
    // background-color: white;
    // box-shadow: 1px 1px 1px black;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
  }

  .khp-media-hotspot__container {
    pointer-events: none;
    position: absolute;
    bottom: 9px;
    left: 50%;
    transform: translateX(-50%);
    width: 4px;

    & > * {
      pointer-events: all;
    }

    &::after {
      content: '';
      width: 0px;
      height: auto;
      border-right: 2px solid white;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 50%;
      transform: translateX(-1px);
      box-shadow: 0.5px 0.5px 0.5px rgba(0, 0, 0, 0.3);
      // box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
    }

    &.project-gate {
      transform: translate(-50%, 122%);
    }
  }

  .khp-media-hotspot__title {
    color: white;
    position: absolute;
    width: auto;
    min-width: 110px;
    top: 0;
    left: 50%;
    transform: translate(-20%, -100%);
    // background-color: white;
    background-color: rgba(18, 62, 61, 0.65);
    // border-radius: 4px;
    padding: 5px 15px;
    // box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
    border-radius: 6px;

    .khp-media-hotspot__preview {
      display: none;
    }

    // &:hover {
    //   background-color: rgba(255, 192, 0, 1);
    //   box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.7);
    // }

    &.offsite {
      background-color: rgba(233, 113, 50, 0.7);
    }

    &.project-gate {
      transform: translate(-30px, 40px);
    }

    &.road {
      background-color: rgba(78, 149, 217, 0.7);
    }

    p {
      white-space: nowrap;
      margin: 0;
      text-align: center;
      // color: rgb(6, 66, 144);
      color: white;
      font-size: 10px !important;
      text-transform: capitalize;
      font-weight: 500;
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);

      &.text-left {
        text-align: left;
      }

      @media (min-width: 1024px) {
        font-size: 10px;
      }
    }
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.65);

    &::before {
      // background-color: rgba(255, 255, 255, 0.8);
      background-color: white;
    }

    .khp-media-hotspot__title {
      opacity: 1;
      background-color: rgba(255, 192, 0, 1);
      // box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.7);

      .khp-media-hotspot__preview {
        display: block;
        position: absolute;
        bottom: 100%;
        left: 50%;
        transform: translate(-50%, -3px);
        right: 0;
        height: auto;
        width: 100%;
        min-width: 200px;
        object-fit: cover;
        border-radius: 0;
        border: none;
      }

      &.offsite {
        background-color: rgba(233, 113, 50, 1);
      }

      &.road {
        background-color: rgba(78, 149, 217, 1);
      }
    }
  }

  &.big-bottom {
    width: 30px;
    height: 30px;
    background-color: rgba(227, 207, 143, 0.5);
    pointer-events: all;
    cursor: pointer;
    box-shadow: none;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 20px;
      height: 20px;
      background-color: #e3cf8f;
      border-radius: 50%;
    }

    &::after {
      display: block;
      position: absolute;
      content: '';
      width: 20px;
      height: 20px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 100%;
      // border: 1px solid #d6b623;
      background-color: transparent;
      animation: mediaPulsePoint 1s infinite;
    }

    .khp-media-hotspot__container {
      bottom: 27px;
    }

    @keyframes mediaPulsePoint {
      0% {
        box-shadow: 0 0 0 0 rgb(255, 255, 255);
      }

      70% {
        box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
      }

      100% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
      }
    }
  }
}
