.loading-container {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  padding: 15px;
  background: #123e3d;
  opacity: 1;
  transition: opacity 2.5s ease-in-out;
  pointer-events: none;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  &.fade-out {
    opacity: 0;
  }

  .loading-pattern {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .loading-image {
    width: 50%;
    max-width: 250px;
    animation: blink-opacity 1s linear;
    padding-bottom: 30px;

    @keyframes blink-opacity {
      0% {
        opacity: 0;
      }

      50% {
        opacity: 0.5;
      }

      100% {
        opacity: 1;
      }
    }

    img {
      width: 80%;
      margin: auto;
      display: block;
      // border-radius: 10px;
    }

    .loading-bar {
      width: 100%;
      height: 4px;
      background-color: rgba(255, 255, 255, 0.3);
      border-radius: 2px;
      overflow: hidden;
      margin-top: 30px;

      .loading-progress {
        height: 4px;
        background-color: white;
        transition: all 0.5s;
      }
    }

    @media screen and (max-width: 991px) {
      // width: 50%;
    }
  }
}
