.daytime-switch {
  height: 24px;
  width: 50px;
  padding: 0 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  background-color: #c7d2de;
  // border: 1px solid #f1eab0;
  border-radius: 20px;
  flex-direction: row;
  cursor: pointer;
  box-shadow: 5px 2px 15px rgba(0, 0, 0, 0.3);
  transition: 0.3s ease;
  z-index: 10;
  pointer-events: all;

  &.night {
    // border: 2px solid #a0b2c3;
    background-color: #105260;
    flex-direction: row-reverse;
  }

  @media (min-width: 768px) {
    height: 30px;
    width: 80px;
    padding: 0 3px;
  }

  @media (min-width: 1500px) {
    height: 35px;
    width: 90px;
    padding: 0 3px;
  }

  &-icon {
    height: 20px;
    width: 20px;

    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);

    svg {
      scale: 1;
    }

    &.night {
      background-color: #a0b2c3;
    }

    @media (min-width: 768px) {
      height: 26px;
      width: 26px;

      svg {
        scale: 1.15;
      }
    }

    @media (min-width: 1500px) {
      height: 30px;
      width: 30px;

      svg {
        scale: 1.3;
      }
    }
  }

  &-text {
    text-align: center;
    flex: 1;
    font-size: 11px;
    font-weight: bold;
    color: #3b3f42;

    &.night {
      color: #98a861;
    }

    .dt-text-content {
      display: none;
    }

    @media screen and (min-width: 768px) {
      font-size: 12px;

      .dt-text-content {
        display: inline;
        padding-right: 10px;
      }

      &.night {
        .dt-text-content {
          padding-right: 0;
          // padding-left: 10px;
        }
      }
    }
  }
}

.khp-menu-open {
  .daytime-switch {
    @media screen and (min-width: 768px) {
      // transform: translateX(calc((-50vw + 275px)));
    }
  }
}
