.bglayer {
  position: fixed;
  inset: 0;

  background-image: url('./Larita_Loading_BG_xs.webp');
  background-position: center;
  background-size: cover;
  z-index: -1;

  @media (min-width: 768px) {
    background-image: url('./Larita_Loading_BG_md.webp');
  }

  @media (min-width: 1024px) {
    background-image: url('./Larita_Loading_BG_lg.webp');
  }

  @media (min-width: 3000px) {
    background-image: url('./Larita_Loading_BG_xl.webp');
  }
}
